@import "@vectara/vectara-ui/lib/sassUtils/index";

.headerCustomerId {
  display: none;
}

.signOutBlock {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $screenBlockZIndex;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $colorDarkerShade;
  visibility: hidden;
  opacity: 0;
  transition: opacity $transitionSpeed;
  z-index: 2000;
}

.signOutBlock-isVisible {
  visibility: visible;
  opacity: 1;
}

.signOutBlock__message {
  padding: $sizeL;
  max-width: 400px;
  width: 100%;
  pointer-events: none;

  p {
    color: $colorEmptyShade !important;
  }
}

@import "@vectara/vectara-ui/lib/sassUtils/index";

@keyframes appNavIn {
  0% {
    transform: translateX(-$appSideNavWidth);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.appNav {
  padding: 0;
  position: fixed;
  top: 0 !important;
  bottom: 0;
  left: 0;
  width: $appSideNavWidth;
  border-radius: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
  z-index: 9;
  background-color: $colorEmptyShade;
  box-shadow: $shadowLargeEnd;
  animation: appNavIn 0.075s cubic-bezier(0, 1, 1, 1);

  .vuiAppSideNav {
    height: 100%;
    border-right: 1px solid $colorEmptyShade;
  }

  .vuiAppSideNav__inner {
    padding: $sizeL;
  }

  .vuiAppSideNavCollapseButton {
    display: none;
  }
}

.appNav-isHidden {
  display: none;
}

.appNav-isPinned {
  box-shadow: none;

  .vuiAppSideNav {
    border-right: 1px solid $borderColorLight;
  }

  .appNavControls {
    border-bottom-color: $borderColor;
  }
}

.appNavControls {
  padding: 0 $sizeM;
  height: $appHeaderHeight;
  border-bottom: 1px solid $borderColorLight;
}

.appNavLogoContainer {
  border-left: 1px solid $borderColor;
  padding-left: $sizeS;
  height: $sizeL;
  display: flex;
  align-items: center;
}

.appNavLogo {
  height: $sizeM;
}

.unreadUpdatesBadge {
  margin-left: $sizeXxs;
  line-height: 1;
}

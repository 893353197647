@import "@vectara/vectara-ui/lib/sassUtils/index";

.appHeaderSearchContainer {
  width: 100%;
}

.apiMenu {
  width: 280px;
}

.headerBreadcrumbLabel > * {
  font-weight: $fontWeightStrong !important;
  padding-left: $sizeXs;
}

.breadcrumbSeparator {
  opacity: 0.4;
}

@import "@vectara/vectara-ui/lib/sassUtils/index";

.onboardingSurveyContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $colorDarkShade;
  align-items: center;
  justify-content: center;
}

.onboardingSurvey {
  width: 100%;
  flex-shrink: 1;
  max-width: 800px;
  padding: 32px 64px;
  border: 1px solid $borderColor;
  border-radius: $sizeM;
  background-color: $colorEmptyShade;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.onboardingSurveyContent {
  width: 100%;
  max-width: 640px;
}
